import React, { Component } from 'react';
import Head from 'next/head';
import Login from '@studionand/depi/src/components/depv/Login';
import DepvLayout from '@studionand/depi/src/components/DepvLayout';

class DepvLoginPage extends Component {
  static async getInitialProps(ctx) {
    return {};
  }

  render() {
    return (
      <DepvLayout>
        <Head>
          <title>DEPV - Anmelden</title>
        </Head>
        <Login />
      </DepvLayout>
    );
  }
}

export default DepvLoginPage;
